import { Picture, useUI } from '@components/ui'
import styles from './Subscribe.module.css'
import cn from 'classnames'
import { getAdCookie } from '@lib/utils/cookies'
import { useEffect, useRef, useState } from 'react'
import { regExp } from '@lib/utils/regExp'
import fetcher from '@lib/fetcher'
import { BRAND } from '@shopify/const'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { motion, useScroll, useTransform, useParallax } from 'framer-motion'
import { easingTransitions } from 'framework/lib/utils/animate'
import SolixButton from '@components/control/SolixButton'
import { sha256 } from '@lib/utils/tools'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Subscribe = ({ pageType, className, metafields, locale, data, slug }) => {
  let timer = null
  const {
    footerSetting = {},
    footerMenu = {},
    copywriting,
    footerCountry = {},
    preRender = {},
  } = metafields

  const { setHtmlRelativeLink } = useRelativeLink()

  const [disabled, setDisabled] = useState(true)
  const [email, setEmail] = useState('')
  const [isError, setIsError] = useState(true)
  const [formError, setFormError] = useState('')
  const [policy, setPolicy] = useState(false)
  const [member, setMember] = useState(false)
  const [shakeAn, setShake] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isImpression, setIsImpression] = useState(false)

  const btnRef = useRef(null)
  const targetRef = useRef(null)
  const showFormError = ({ type, error }) => {
    setFormError(error || copywriting[type] || 'unknown error')
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setFormError('')
    }, 3000)
  }

  const footerCategory = () => {
    switch (pageType) {
      case 'powerStation':
        return 'footer_sub_pps'
      case 'charger':
        return 'footer_sub'
      case 'hes':
        return 'footer_sub_hes'
    }
  }

  const { recaptchaReady } = useUI()

  useEffect(() => {
    if (recaptchaReady) {
      setDisabled(false)
    }
  }, [recaptchaReady])

  useEffect(() => {
    if (targetRef.current) {
      const targetPosition =
        targetRef.current.getBoundingClientRect().top + window.pageYOffset
      const windowHeight = window.innerHeight
      // 判断是否滚动到目标元素的位置
      if (scrollPosition > targetPosition - windowHeight && !isImpression) {
        // 在此执行您的操作
        // console.log('已滚动到目标元素位置');
        pageGTMEvent({
          event: 'uaEvent',
          eventCategory: footerCategory(), //中充页脚传footer_sub_pps
          eventAction: '',
          eventLabel: 'impression',
          nonInteraction: true,
        })
        pageGTMEvent({ event_parameters: null })
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: footerCategory(), //中充页脚传footer_sub_pps
            position: '',
            button_name: 'impression',
          },
        })
        setIsImpression(true)
      }
    }
  }, [scrollPosition])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleSubscribe = async () => {
    setIsError(true)

    if (isOpen) {
      setIsOpen(false)
      return null
    }

    if (!email) {
      return showFormError({ type: 'fillEmail' })
    }
    if (!regExp.email.test(email)) {
      return showFormError({ type: 'errEmail' })
    }
    if (!policy) {
      setShake(true)
      return showFormError({ type: 'agreePolicy' })
    }
    setDisabled(true)
    const { register_source } = getAdCookie()
    const sub_brand_type_content = () => {
      let text = ''
      switch (pageType) {
        case 'powerStation':
          return (text = 'anker_powerhouse')
        case 'charger':
          return (text = '')
        case 'hes':
          return (text = 'anker_HES_balkonkraftwerk')
      }
    }
    const brandDealsType = () => {
      switch (pageType) {
        case 'powerStation':
          return 'anker_powerhouse'
        case 'charger':
          return 'anker_charger'
        case 'hes':
          return 'anker_balcony'
        default:
          return 'footer_sub'
      }
    }
    if (member) register(email.toLowerCase())
    const result = await fetcher({
      locale,
      url: '/api/multipass/rainbowbridge/activities',
      method: 'POST',
      action: 'activity',
      needRecaptcha: true,
      body: {
        email: email.toLowerCase(),
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: brandDealsType(),
      },
    })
    setDisabled(false)
    if (!result.errors) {
      if (data?.fbqEvent && window.fbq) {
        fbq('track', data?.fbqEvent)
      }
      showFormError({ type: 'footerSuccess' })
      setEmail('')
      setIsError(false)
      setIsOpen(true)
      setShake(false)
      pageGTMEvent({
        subscribe_hashed_email: email ? sha256(email.toLowerCase()) : '',
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'subscribe',
        event_parameters: {
          page_group: `Home Page${pageType == 'charger' ? '' : '_pps'}`,
          positon: 'footer',
        },
      })
      pageGTMEvent({ event_parameters: null })
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'subscribe',
        eventAction: 'footer',
        page_group: `Home Page${pageType == 'charger' ? '' : '_pps'}`,
        eventLabel: slug,
        nonInteraction: false,
      })
    } else {
      setShake(false)
      showFormError({ error: result.errors || result.statusText })
    }
  }

  const register = async (email) => {
    const { register_source } = getAdCookie()
    const res = await fetcher({
      locale,
      url: '/api/multipass/account/registrations/quick',
      method: 'POST',
      body: {
        email,
        register_source: register_source,
      },
    })
    if (
      (res.status > 100 && res.status < 300) ||
      res?.data?.error_code === '114'
    ) {
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'user_center',
        event_parameters: {
          page_group: 'Activity Page_' + slug,
          position: 'register',
          button_name: data?.btnText,
          subscribe_box: 1,
          action: 'success',
        },
      })
    } else {
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'user_center',
        event_parameters: {
          page_group: 'Activity Page_' + slug,
          position: 'register',
          button_name: data?.btnText,
          subscribe_box: 1,
          action: 'fail',
        },
      })
    }
  }

  let itemVariants = {
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        stiffness: 400,
        damping: 40,
        delay: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
        stiffness: 20,
        damping: 2,
      },
    },
  }

  let itemVariantsBtn = {
    show: {
      width: '100%',
      transition: { duration: 0.5 },
    },
  }

  let itemVariantsInput = {
    hidden: {
      opacity: 0,
      transition: { duration: 0.5, delay: 0.5 },
    },
  }

  const [isOpen, setIsOpen] = useState(false)
  if (!data) return null
  return (
    <>
      {!preRender?.hideHomeSubscribe && (
        <div className="relative bg-[#000]">
          <motion.section
            // layout
            ref={targetRef}
            className={cn(styles.subScribe_layer, 'layer footer', {
              [styles?.light]: data?.theme === 'light',
              [styles?.abosulte]: data?.imgHeight,
              [styles.memberBoxLayer]: data?.member,
            })}
          >
            <motion.div
              // layout
              className={cn(styles.subScribe_content, 'content')}
            >
              <motion.div className={styles.title_grid}>
                <motion.p
                  // layout
                  className={styles.subScribe_content_subTitle}
                  dangerouslySetInnerHTML={{
                    __html: data?.subTitle,
                  }}
                />
                <motion.p
                  // layout
                  className={styles.subScribe_content_title}
                  dangerouslySetInnerHTML={{
                    __html: data?.title,
                  }}
                />

                {data?.chosen && (
                  <motion.p
                    // layout
                    className={cn(
                      styles.subScribe_content_subTitle,
                      styles.subScribe_content_chosen
                    )}
                    dangerouslySetInnerHTML={{
                      __html: data?.chosen,
                    }}
                  />
                )}
              </motion.div>

              <motion.div className={styles.subScribe_content_btnBox_grid}>
                <motion.div
                  // layout
                  className={cn(styles.subScribe_content_btnBox, {
                    [styles.subScribe_content_btnBox_border]:
                      pageType === 'powerStation' || pageType === 'hes',
                  })}
                >
                  {pageType === 'powerStation' || pageType === 'hes' ? (
                    <motion.div
                      className={cn(styles.subScribe_content_inpu_border, {
                        [styles.memberInput]: data?.member,
                      })}
                    >
                      <motion.input
                        layout
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value?.trim())}
                        className={cn(styles.subScribe_content_input)}
                        placeholder={data?.placeholder}
                      />
                    </motion.div>
                  ) : (
                    <>
                      <motion.input
                        // layout
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value?.trim())}
                        className={cn(styles.subScribe_content_input, {
                          [styles.subScribe_content_inpu_border]:
                            pageType === 'powerStation' || pageType === 'hes',
                        })}
                        placeholder={data?.placeholder}
                        // animate={isOpen ? 'hidden' : ''}
                        // variants={itemVariantsInput}
                      />
                    </>
                  )}
                  {pageType === 'powerStation' || pageType === 'hes' ? (
                    <div className="h-[52px] max-w-[200px] bg-white md:relative md:mt-[10px] md:h-[40px] md:w-full md:max-w-none md:rounded-[30px]">
                      <SolixButton
                        disabled={disabled}
                        onClick={handleSubscribe}
                        className={cn(styles.solixButton, {
                          [styles.solixMember]: data?.member,
                        })}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data?.btnText,
                          }}
                        ></p>
                      </SolixButton>
                    </div>
                  ) : (
                    <motion.div className={styles.subScribe_content_btn_box}>
                      <motion.button
                        ref={btnRef}
                        layout
                        type="submit"
                        onClick={handleSubscribe}
                        disabled={disabled}
                        className={styles.subScribe_content_btn}
                        // animate={isOpen ? 'show' : ''}
                        // variants={itemVariantsBtn}
                      >
                        <motion.p
                          layout
                          // variants={itemVariants}
                          className="absolute left-0 top-0 h-full w-full px-[16px] md:px-[14px]"
                          // animate={isOpen ? 'hidden' : 'show'}
                          dangerouslySetInnerHTML={{
                            __html: data?.btnText,
                          }}
                        ></motion.p>
                        {/* {isOpen && (
                          <motion.p
                            layout
                            variants={itemVariants}
                            inherit
                            animate={isOpen ? 'show' : 'hidden'}
                            className="absolute left-0 top-0 h-full w-full pl-[36px] pr-[36px]"
                            dangerouslySetInnerHTML={{
                              __html: data?.successBtnText,
                            }}
                          ></motion.p>
                        )} */}
                      </motion.button>
                    </motion.div>
                  )}
                </motion.div>
              </motion.div>

              <motion.div
                className={cn(styles.policy_box, {
                  [styles.shake_box]: shakeAn,
                  [styles.alignment]: data?.member,
                })}
                style={{ color: data?.textColor }}
              >
                <motion.label>
                  <motion.span className={styles.checkoutBox}>
                    <motion.input
                      className={styles.checkout}
                      type="checkbox"
                      checked={policy}
                      onChange={(e) => setPolicy(e.target.checked)}
                    />
                  </motion.span>
                  <motion.span
                    dangerouslySetInnerHTML={{
                      __html: setHtmlRelativeLink({ html: data?.tips }),
                    }}
                  />
                </motion.label>
              </motion.div>
              {data?.member && (
                <motion.div
                  className={cn(
                    styles.policy_box,
                    styles.memberBox,
                    styles.alignment,
                    {
                      [styles.shake_box]: shakeAn,
                    }
                  )}
                  style={{ color: data?.textColor }}
                >
                  <motion.label>
                    <motion.span className={styles.checkoutBox}>
                      <motion.input
                        className={styles.checkout}
                        type="checkbox"
                        checked={member}
                        onChange={(e) => setMember(e.target.checked)}
                      />
                    </motion.span>
                    <motion.span
                      dangerouslySetInnerHTML={{
                        __html: data?.member,
                      }}
                    />
                  </motion.label>
                </motion.div>
              )}
              <motion.p
                className={`${styles.form_error} ${
                  isError ? styles.error : styles.success
                }`}
                style={{ opacity: formError ? 1 : 0 }}
              >
                {formError}
              </motion.p>
            </motion.div>
          </motion.section>
        </div>
      )}
    </>
  )
}
export default Subscribe
